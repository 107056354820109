<template>
  <section class="main-wrapper">
    <div class="h-100 row">
      <div class="col-md-4 h-100 ad">
        <div class="mx-auto h-100 flex-column d-flex justify-content-between w-75">
          <div class="pt-3">
            <img src="@/assets/images/exxtra/exxtra-logo.png" width="70px" alt="exxtra-logo" />
          </div>

          <div class="">


            <ol class="position-relative border-left list-unstyled">
              <li class="mb-5 ml-3">
                <span class="position-absolute d-flex align-items-center text-primary justify-content-center rounded-full stepper-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                    <path d="M3.5 2A1.5 1.5 0 002 3.5V5c0 1.149.15 2.263.43 3.326a13.022 13.022 0 009.244 9.244c1.063.28 2.177.43 3.326.43h1.5a1.5 1.5 0 001.5-1.5v-1.148a1.5 1.5 0 00-1.175-1.465l-3.223-.716a1.5 1.5 0 00-1.767 1.052l-.267.933c-.117.41-.555.643-.95.48a11.542 11.542 0 01-6.254-6.254c-.163-.395.07-.833.48-.95l.933-.267a1.5 1.5 0 001.052-1.767l-.716-3.223A1.5 1.5 0 004.648 2H3.5zM16.5 4.56l-3.22 3.22a.75.75 0 11-1.06-1.06l3.22-3.22h-2.69a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V4.56z" />
                  </svg>                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Contact Info
                </h4>
                <p class="m-0 p-0 lead text-white">
                  Provide contact information
                </p>
              </li>
              <li class="mb-5 ml-3">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
                </span>
                <h4 class="m-0 p-0 secondary-heading">
                  Education Info
                </h4>
                <p class="m-0 p-0 text-white">
                  Provide information about your studies
                </p>
              </li>
              <li class="mb-5 ml-3">
                <span class="position-absolute d-flex align-items-center justify-content-center rounded-full stepper-icon">
                  <svg aria-hidden="true" class="" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                    <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2
                     2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                          clip-rule="evenodd"
>
                    </path>
                  </svg>
                </span>
                <h1 class="m-0 p-0 secondary-heading">
                  Success
                </h1>
                <p class="m-0 p-0 text-white">
                  You have successfully registered
                </p>
              </li>
            </ol>

          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="col-md-8 h-100">
        <div class="h-100 flex-column d-flex align-items-center justify-content-center ">
          <div>
            <div class="text-center">
              <h1 class="py-2">
                Thank you for choosing Exxtra
              </h1>
            </div>
            <div>
              <h3>Your graduate school loan application has been lodged successfully</h3>
              <h4 class="text-center">
                Stay in touch, We will contact you soon
              </h4>
            </div>
            <div class="text-center mt-2 pt-1">
              <h6>{{ getValueFromSource(graduate_applicant, 'full_name') }}</h6>
              <h6>{{ getValueFromSource(graduate_applicant, 'email') }}</h6>
              <h6>{{ getValueFromSource(graduate_applicant, 'phone') }}</h6>
            </div>
            <div class="text-center my-3">
              <KiteIcon />
            </div>
            <div class="text-center">
              <a @click="$router.push({ name: 'gradloans'})">
                Go Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import KiteIcon from "@/assets/svg/KiteIcon.svg";

export default {
  name: "GraduateRegistered",
  components: {
    KiteIcon
  },
  data() {
    return {
      graduate_applicant: {}
    }
  },
  mounted() {
    const graduate_applicant = JSON.parse(localStorage.getItem('graduate_applicant'))
    if (!graduate_applicant) this.$router.push({ name: 'gradloans-contact' })
    this.graduate_applicant = graduate_applicant;
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/base/pages/page-grad-applications.scss';

</style>
